import { StaticImage } from "gatsby-plugin-image"
import React, { PropsWithChildren } from "react"
import Footer from "./Footer"
import SiteMenu from "./siteMenu"
import { PageProps } from "gatsby"

type PropTypes = {
  location: PageProps["location"]
  showPaymentRedirectLoading?: boolean
}

export default function PageWithBackgroundImage({
  children,
  location,
  showPaymentRedirectLoading = false,
}: PropsWithChildren<PropTypes>) {
  return (
    <div className="pageWithBackground" id="root">
      <StaticImage
        style={{ width: "100vw", height: "100%" }}
        // fluid={data.file.childImageSharp.fluid}
        src="../images/background.jpeg"
        imgStyle={{ objectPosition: "bottom", zIndex: -1 }}
        alt="Background image gazmir.ro"
      />
      <div className="pwbg-container">
        <SiteMenu
          location={location}
          showPaymentRedirectLoading={showPaymentRedirectLoading}
        />
        {/* <UnderMaintenanceBanner /> */}
        {children}
        <Footer />
        {/* <CookieBanner /> */}
      </div>
    </div>
  )
}
