import React from "react"
import { colors } from "../styles/globals"
import InfoSteps from "./Cereri/InfoSteps"
import ReturnHomeButton from "./Cereri/ReturnHomeButton"
import svgs from "./Dash/svgs"
import { PaymentData } from "../utils/api/payments"

type PropTypes = {
  paymentData: PaymentData
}

const styles = {
  textStyle: {
    color: colors.main,
    fontSize: 16,
  },
}
const FACTURA = 1
const PROGRAMARE_REVIZIE = 2
const DOVADA_REVIZIE = 3
const FORMULAR_CONTACT = 4
const PLANGERI_SESIZARI = 5
const VERIFICARE_METROLOGICA = 6
const SCHIMBARE_TITULAR = 7
const CERERE_AUDIENTA = 8
const DUPLICAT_CONTRACT = 9
const CERERE_CONTRACT_FURNIZARE = 10
const SCHIMBARE_ADMINISTRATIVA = 11
const AVIZ_AMPLASAMENT = 12

const titluSchimbareAdministrativa =
  "Cererea a fost transmisă cu succes. Etapele procesului de modificare de natură administrativă (schimbare titular) :"
const stepsSchimbareAdministrativa = [
  `1. Completarea datelor necesare de către noul titular`,
  `2. Anexarea documentelor necesare`,
  `3. Achitarea tarifului de analiză a cererii`,
  `4. Primirea validării documentației depuse (sau, după caz, a completărilor necesare)`,
  `5. Achitarea soldului restant - necesar pentru efectuarea modificării de natura administrativă`,
  `6. Aprobarea cererii`,
  `7. Incheierea noului contract de furnizare`,
]

const titluAvizAmplasament =
  "Cererea a fost transmisă cu succes. Etapele pentru aprobarea cererii de Aviz de Amplasament:"
const stepsAvizAmplasament = [
  `1. Completarea datelor necesare de către noul titular`,
  `2. Anexarea documentelor necesare`,
  `3. Primirea validării documentației depuse (sau, după caz, a completărilor necesare)`,
  `4. Achitarea tarifului de analiză a cererii`,
  `5. Achitarea soldului restant - necesar pentru efectuarea modificării de natura administrativă`,
  `6. Aprobarea cererii`,
  `7. Incheierea noului contract de furnizare`,
]

function PaymentsDetailsCereri({ paymentData }: PropTypes) {
  switch (paymentData.customData.tipCerere) {
    case PROGRAMARE_REVIZIE:
      return (
        <div style={{ ...styles.textStyle, padding: "20px 24px" }}>
          În cel mai scurt timp veți fi contactat pentru a vi se confirma
          programarea
        </div>
      )
    case VERIFICARE_METROLOGICA:
      return (
        <div style={{ ...styles.textStyle, padding: "20px 24px" }}>
          În cel mai scurt timp veți fi contactat pentru a vi se efectua
          verificarea metrologică
        </div>
      )
    case SCHIMBARE_ADMINISTRATIVA:
      return (
        <InfoSteps
          title={titluSchimbareAdministrativa}
          steps={stepsSchimbareAdministrativa}
        />
      )
    case AVIZ_AMPLASAMENT:
      return (
        <InfoSteps title={titluAvizAmplasament} steps={stepsAvizAmplasament} />
      )
  }
}

function getTitluByPaymentStatus(status) {
  switch (status) {
    case 0:
      return ["Plată în curs de validare!"]
    case 1:
      return ["Plata efectuată cu succes!"]
    case 2:
      return [
        "Plata dumneavoastră nu a fost procesată cu succes. ",
        "Vă rugăm reîncercați",
      ]
    default:
      return [
        "Plata dumneavoastră nu a fost procesată cu succes. ",
        "Vă rugăm reîncercați",
      ]
  }
}

export default function PaymentsInfo({ paymentData }) {
  let detailsComponent
  if (paymentData.status === 0 || paymentData.status === 1)
    if (paymentData.customData.isPlataFacturi)
      detailsComponent = (
        <div
          style={{
            ...styles.textStyle,
            // padding: "20px 24px",
            marginTop: 20,
            textAlign: "center",
          }}
        >
          Contul dumneavoastră va fi actualizat în cel mai scurt timp
          <div className="cereriRow textAlignCenter buttonRowPaymentFacturi">
            <ReturnHomeButton />
          </div>
        </div>
      )
    else detailsComponent = <PaymentsDetailsCereri paymentData={paymentData} />

  return (
    <div
      style={{
        textAlign: "justify",
        color: colors.main,
        fontSize: 18,
        padding: "10px 20px",
      }}
    >
      {(paymentData.status === 0 || paymentData.status === 1) && (
        <div style={{ height: 50, width: 50, margin: "0 auto" }}>
          {svgs.checked}
        </div>
      )}

      <div
        style={{
          color: colors.main,
          fontSize: 18,
          fontWeight: "bold",
          paddingTop: 10,
          textAlign: "center",
        }}
      >
        {getTitluByPaymentStatus(paymentData.status).map(item => (
          <span
            style={{
              display: "block",
              color:
                paymentData.status === 0
                  ? colors.main
                  : paymentData.status === 1
                  ? colors.green
                  : colors.red,
            }}
          >
            {item}
          </span>
        ))}
      </div>
      <div
        style={{
          color: colors.main,
          fontSize: 12,
          // fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Id tranzacție : {paymentData.transactionId || paymentData._id}
      </div>
      {detailsComponent}
    </div>
  )
}
