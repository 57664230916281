import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import Spinner from "../components/Spinner"
import { colors } from "../styles/globals"
import PageWithBackgroundImage from "../components/pageWithBackgroundImage"
import { getAllUrlParams } from "../utils"
import { PaymentData, getPaymentById } from "../utils/api/payments"
import PaymentsInfo from "../components/PaymentsInfo"
import { Helmet } from "react-helmet"
import { PageProps } from "gatsby"

export default function PaymentPage({ location }: PageProps) {
  const [infoMsg, setInfoMsg] = useState<string | null>("Vă rugăm așteptați")
  const [paymentData, setPaymentData] = useState<PaymentData | null>(null)

  useEffect(() => {
    const getPaymentData = async orderId => {
      try {
        const apiResponse = await getPaymentById(orderId)
        if (apiResponse.type) {
          setInfoMsg(null)
          setPaymentData(apiResponse.data)
        } else
          setInfoMsg(
            "Id-ul tranzacției este invalid. Dacă ați efectuat o plată și ați fost redirecționat aici vă rugăm să ne contactați."
          )
        // console.log(apiResponse)
        // if(pa)
      } catch (error) {
        console.log(error)
      }
    }
    const { orderId } = getAllUrlParams(location.search)

    getPaymentData(orderId)
  }, [location.search])

  return (
    <PageWithBackgroundImage location={location}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="paymentModal">
        <StaticImage
          style={{ marginTop: 20 }}
          height={120}
          loading="eager"
          layout="constrained"
          src="../images/logoNou.png"
          alt="Logo gazmir"
        />
        <Spinner
          // isDark={false}
          containerStyle={{
            width: 41,
            height: 41,
            margin: "40px auto 0px auto",
          }}
          show={infoMsg === "Vă rugăm așteptați"}
        />
        {paymentData && <PaymentsInfo paymentData={paymentData} />}
        {infoMsg && (
          <div
            style={{
              padding: "50px 20px",
              // marginTop: 32,
              textAlign:
                infoMsg === "Vă rugăm așteptați" ? "center" : "justify",
              fontSize: 18,
              color: colors.main,
            }}
          >
            {infoMsg}
          </div>
        )}
      </div>
    </PageWithBackgroundImage>
  )
}
